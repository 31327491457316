import { lazy } from 'react';
import { Path } from './RouteRenderer';
import { Redirect } from 'react-router-dom';
import { CONFIG } from '../Config/constants';
import { AuthPath, GuestPath } from './Guard';

export const routesArray: Path[] = [
	{
		exact: true,
		path: ['/reset-password/:token'],
		component: lazy(() => import('../../Views/Pages/Login/ResetPassword/ResetPassword')),
	},
	{
		exact: true,
		guard: GuestPath,
		path: ['/login'],
		component: lazy(() => import('../../Views/Pages/Login/Login')),
	},
	{
		exact: false,
		guard: AuthPath,
		path: ['/', '/panel'],
		component: lazy(() => import('../../Views/Layouts')),
	},

	{
		path: ['*'],
		exact: true,
		component: () => <Redirect to={CONFIG.BASE_URL} />,
	},
];

export default routesArray;
