import { FC } from 'react';
import useStore from '../../Tools/Store/useStore';
import useAccount from '../../Tools/Hooks/useAccount';
import { RoleProps } from '../../Components/Role/Role';
import { Redirect, useHistory } from 'react-router-dom';
import { isTokenValid } from '../../Tools/Hooks/useAccount';
import { logout } from '../../Tools/Store/actions/AccountActions';

export const GuestPath: FC = ({ children }) => {
	const { dispatch } = useStore();
	const { loggedIn, user } = useAccount();
	if (loggedIn && !isTokenValid(user?.token)) dispatch(logout());
	if (loggedIn) return <Redirect to='/panel' />;
	return <>{children}</>;
};

export const AuthPath: FC = ({ children }) => {
	const { dispatch } = useStore();
	const { location, push } = useHistory();
	const { loggedIn, user } = useAccount();

	const key = 'redirect-path-after-auth';

	if (loggedIn && !isTokenValid(user?.token)) dispatch(logout());

	if (loggedIn) {
		const redirectTo = localStorage?.getItem(key);
		if (redirectTo) {
			localStorage.removeItem(key);
			push(redirectTo);
		}
	} else {
		localStorage.setItem(key, location?.pathname);
		return <Redirect to='/login' />;
	}

	return <>{children}</>;
};

export const RolePath: FC<RoleProps> = ({ children, is, not }) => {
	// const PERMISSION_ERROR_CONTACT_ADMIN =
	// 	'If you believe this is a mistake, please contact your system administrator.';

	return <>{children}</>;
};

const Guard = { AuthPath, GuestPath };

export default Guard;
