//? ------------------------------ Configs --------------------------------------

const BASE = {
	BASE_URL: '/',
	APP_SHORT_NAME: 'DMP',
	APP_NAME: 'DMP Customer',
};

const PRODUCTION = {
	_mode: 'PRODUCTION' as typeof window.MODE,
	SERVER: 'https://back.dmp.market',
	FRONTEND: 'https://dmp.market',
};

//? --------------------- Config selection ----------------------------------------

export type CONFIG_TYPE = typeof BASE & typeof PRODUCTION;

export const CONFIG: CONFIG_TYPE = { ...BASE, ...PRODUCTION };

window.CONFIG = CONFIG;
